import React from "react";

// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css";

// We import NavLink to utilize the react router.
import { NavLink } from "react-router-dom";
import styled from 'styled-components'


const Nav = styled.div`

    z-index:1;
    height: 100%;
  position: sticky; top: 0;
  display: flex;
  direction: rtl;
  background: #866344;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
   
  `;
const Image = styled.img`
  padding: .5rem;
  margin-right: 1rem;
  aspect-ratio: 1/1;
  width: 80px;
  @media (max-width: 600px) {
    width: 80px;
    }
`;
const Title = styled.h3`
font-family: 'Amiri', serif;
margin-right:  1rem;
margin-top: 2rem;
font-weight: bold;
@media (max-width: 600px) {
    font-size: 1.5em;
    }

`;

// Here, we display our Navbar
export default function Navbar() {
  return (

    <Nav >
      <Image src="https://cdn-icons-png.flaticon.com/512/3227/3227053.png" alt='' to="/"></Image>
      <Title to="/">مكتبــــة بيــت الحـــكمــة</Title>
    </Nav>


  );
}