import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import { RibbonContainer, RightCornerRibbon, LeftCornerRibbon } from "react-ribbons";
import {FiSearch} from 'react-icons/fi';
import '../style.css';
//const hostname = '192.168.100.148:5000'
const hostname = 'https://house-of-wisdom-catalog.herokuapp.com'

const Main = styled.div`
padding: 0.5rem;
  `;


const MainGrid = styled.div`
  margin-left: 1em;
  margin-right: 1em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;
  
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }

  
  `;

const SurroundRibbon = styled.div`
 justify-content: center;
 align-items: center;

`;

const InfoWrapper = styled.div`
  
  justify-content: center;
  text-align: center;
  @media (max-width: 600px) {
    display: flex;
    }
`;
const Cover = styled.img`
  margin-top: 1rem;
  @media (max-width: 600px) {
    flex:1;
    padding-left: 1rem;
    padding-bottom: 1rem;
    }
`;


const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  @media (max-width: 600px) {
    }
`;
const Title = styled.h5`
font-family: 'Cairo', sans-serif;
  text-align: center;
  font-weight: bold;
  
  @media (max-width: 600px) {
    padding-left: 1rem;
    padding-right: 1rem;
    
  }
`;

const Author = styled.h6`
font-family: 'Cairo', sans-serif;
  text-align: center;
  font-weight: bold;
  @media (max-width: 600px) {
  }
`;

const Price = styled.h6`
font-family: 'Rubik', sans-serif;

  @media (max-width: 600px) { 
  }
`;

const SearchBarContainer = styled.span`
  background-color:transparent;
  display: flex;
  padding: 10px 30px;
  margin-bottom: 20px;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  align-items: center;
  border-radius: 10px;
  transition: transform 0.2s;
  background-color:#8B5628A8;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
  :hover {
  cursor: pointer;
  /* transform: scale(1.05); */
}
:active{
    border: none;
    outline:none;
}
  @media (max-width: 600px) {
    
  }
`;
const SearchBar = styled.input`
  direction: rtl;
  width: 100%;
  padding: 20px;
  border: none;
  font-size: 20px;
  background-color:transparent;
  border: none;
    outline:none;
  @media (max-width: 600px) {
    :active{
    border: none;
    outline:none;
}
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4B280AA8;
  opacity: 1; /* Firefox */
}
`;

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});


const Book = (props) => (



  <RibbonContainer
    className="RibbonContainer"
  >
    {props.book.isNew ?

      <SurroundRibbon>
        <LeftCornerRibbon className="RightCornerRibbon" backgroundColor="#099B09" color="#000000" fontFamily="Arial">
          {props.book.isNew ? 'New Arrival' : null}
        </LeftCornerRibbon>

        <InfoWrapper>
          <Cover src={props.book.cover} alt='cover' width="170" height="250"></Cover>
          <TextWrapper>
            <Title>{props.book.title}</Title>
            <Author>{props.book.author}</Author>
            <Price>{formatter.format(props.book.price)} ر.ع</Price>
          </TextWrapper>
        </InfoWrapper>
      </SurroundRibbon>

      :


      <SurroundRibbon>

        <InfoWrapper>
          <Cover src={props.book.cover} alt='cover' width="170" height="250"></Cover>
          <TextWrapper>
            <Title>{props.book.title}</Title>
            <Author>{props.book.author}</Author>
            <Price>{formatter.format(props.book.price)} ر.ع</Price>
          </TextWrapper>
        </InfoWrapper>
      </SurroundRibbon>

    }


  </RibbonContainer>



);

export default function BookList() {
  const [books, setBooks] = useState([]);

  // This method fetches the books from the database.
  useEffect(() => {
    async function getBooks() {
      const response = await fetch(hostname + `/book/`);

      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const books = await response.json();
      setBooks(books);
    }

    getBooks();

    return;
  }, [books.length]);




  // This method will map out the books on the table
  const [searchResult, setSearchResult] = useState([]);
  const [key, setKey] = useState("");
  useEffect(() => {
    const search = async () => {
      try {
        if (!key.trim()) {
          setSearchResult([])
          return
        }

        const response = await fetch(hostname + `/book/` + key);
        const res = await response.json();
        setSearchResult(res)
        console.log(res)



      } catch (e) {
        console.log(e);
      }
    }

    search()
  }, [key])




  function bookList() {
    if (searchResult.length > 0) {
      return searchResult.map((book) => {
        return (
          <Book
            book={book}
            key={book._id}
          />
        );
      });
    } else {
      return books.map((book) => {
        return (
          <Book
            book={book}
            key={book._id}
          />
        );
      });
    }


  }


  // This following section will display the table with the books of individuals.

  return (

    <Main>
      <SearchBarContainer>
        <SearchBar placeholder="بـــحــث" id='SearchBox' value={key} onChange={(e) => setKey(e.target.value)} /><FiSearch size={30}/>

      </SearchBarContainer>
      <MainGrid >{bookList()}</MainGrid>


    </Main>
  );

}
