import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    faInstagram,
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import styled from 'styled-components'





const SocialContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    @media (max-width: 600px) {
    padding-top: 2rem;
    flex-direction: column;
    }
  `;

const Socials = styled.div`
justify-content: center;
display: flex;
flex-direction: row;
flex: 1;
@media (max-width: 600px) {
padding-top: 2rem;
flex-direction: column;
}
`;

const SocialItem = styled.a`
padding-top: 1rem;
    margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
  :hover {
  transform: translateY(-2px);
}
    @media (max-width: 600px) {
        padding-top: 0rem;
    }
  `;

const StyledFontAwesomeTwitter = styled(FontAwesomeIcon)`
    color: #49a1eb;
    font-size: 2.5rem;
    cursor: pointer;
`;
const StyledFontAwesomeInsta = styled(FontAwesomeIcon)`
    color: #77083C;
    font-size: 2.5rem;
    cursor: pointer;
    @media (max-width: 600px) {
        padding-top: 1rem;
    }
`;
const StyledFontAwesomeWhats = styled(FontAwesomeIcon)`
    color: #147C1C;
    font-size: 2.5rem;
    cursor: pointer;
    @media (max-width: 600px) {
        padding-top: 1rem;
    }
`;
const SomethingElse = styled.div`
    flex: 1;
    direction: rtl;
    @media (max-width: 600px) {
    visibility: hidden;
    }
  `;




const Wrapper = styled.div`
    background: #866344C5;
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
    padding: 25px 50px;
    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0px 0px;
    }
  `;


export default function SocialFollow() {
    return (
        <Wrapper>


            <SocialContainer >

                <h3>تابعونا على</h3>
                <Socials>
                    <SocialItem href="https://twitter.com/Houseofwisdom20?" target="_blank">
                        <StyledFontAwesomeTwitter icon={faTwitter} size="3x" />
                    </SocialItem>
                    <SocialItem href="https://www.instagram.com/wisdombookshop/" target="_blank">
                        <StyledFontAwesomeInsta icon={faInstagram} size="3x" />
                    </SocialItem>
                    <SocialItem href="https://wa.me/96890910169/?text=السلام عليكم" target="_blank">
                        <StyledFontAwesomeWhats icon={faWhatsapp} size="3x" />
                    </SocialItem>
                </Socials>
            </SocialContainer>



            <SomethingElse>
                <h4>مكتبــــة بيــت الحـــكمــة</h4>
                <h5>من رفوف مكتبتنا إلى رفوف قرائتكم</h5>
            </SomethingElse>
        </Wrapper>
    );
}