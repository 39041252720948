import React, {useState} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';

import styled from 'styled-components';

export const Button = styled.div`
    display: flex;
    right: 0;
    position: fixed; 
    bottom: 40px;
    height: 20px;
    padding-right: 1rem;
    padding-bottom: 1rem;
    z-index: 1;
    font-size: 3rem;
    color: #af7a4b;
    transition: transform 0.2s;
    :hover {
        cursor: pointer;
        color: #775130;
        transform: scale(1.05); 
}
`
  
const ScrollButton = () =>{
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <Button>
     <FaArrowCircleUp onClick={scrollToTop} 
     style={{display: visible ? 'inline' : 'none'}} />
    </Button>
  );
}
  
export default ScrollButton;