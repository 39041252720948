import React from "react";
import "./style.css";
// We use Route in order to define the different routes of our application
import { Route, Routes } from "react-router-dom";
import ScrollButton from './components/ScrollButton';
import ContactForm from './components/Contact';
 // We import all the components we need in our app
import Navbar from "./components/navbar";
import BookList from "./components/recordList";
import SocialFollow from "./components/SocialFollow"

import styled from 'styled-components'




const Page = styled.div`
   background-color: #8B5628A8;
   min-height: 100vh;
  
  `;



const App = () => {
 return (
   <Page >
    
     <Navbar />
     <Routes>
       <Route exact path="/" element={<BookList />} />
     </Routes>
     
     <ScrollButton />
     <ContactForm/>
     <SocialFollow/>
     
   </Page>
 );
};
 
export default App;