import React, {useRef } from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';

const Wrapper = styled.div`
    justify-content: center;
    direction: rtl;
    margin: 5rem 0 0 0;
    padding-left: 4rem;
    padding-right: 4rem;
    width: 99vw;
    @media (max-width: 600px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  `;
const Form = styled.form`
`;
const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
`;
const NameLabel = styled.label`
    padding-left: 1rem;
`;
const EmailLabel = styled.label`
    padding-left: 1rem;
`;
const MessageLabel = styled.label`
    padding-left: 1rem;
`;

const SubmitButton = styled.button`
    margin-top: 2rem;
    padding: 10px;
    background-color:#866344;
    border: none;
    border-radius: 1rem;
    outline:none;
    transition: transform 0.2s;
    :hover {
        cursor: pointer;
        transform: scale(1.05); 
        background-color:#A17F60;
}
`;
const NameInput = styled.input`
    background-color:#866344;
    border: none;
    border-radius: 1rem;
    outline:none;
    padding-right: 1rem;
    :active{
        border: none;
        outline:none;
}
`;
const EmailInput = styled.input`
    background-color:#866344;
    border: none;
    border-radius: 1rem;
    outline:none;
    padding-right: 1rem;
    :active{
        border: none;
        outline:none;
}
`;
const MessageInput = styled.textarea`
    background-color:#866344;
    border: none;
    border-radius: 1rem;
    outline:none;
    padding-right: 1rem;
    :active{
        border: none;
        outline:none;
}
`;
const ContactForm = () =>{
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_2k3m495', 'template_afglzrh', form.current, 'jA3ZLt_KTnJqFK26F')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
      };

return (
    <Wrapper>
        <h2>تواصل معنا</h2>       
        <Form ref={form} onSubmit={sendEmail} method="POST">
          <FormGroup >
            <NameLabel htmlFor="name">الإسم</NameLabel>
            <NameInput name="name" type="text" required /*  value={this.state.name} onChange={this.onNameChange.bind(this)} */ />
          </FormGroup>
          <FormGroup >
            <EmailLabel htmlFor="exampleInputPhone1">رقم الهاتف</EmailLabel>
            <EmailInput name="phone" type="tel"  aria-describedby="phoneHelp" pattern="[0-9]{8}" required /*  value={this.state.email} onChange={this.onEmailChange.bind(this)}  *//>
          </FormGroup>
          <FormGroup>
            <MessageLabel htmlFor="message">الرسالة\الطلب</MessageLabel>
            <MessageInput name="message"  rows="5" required/* value={this.state.message} onChange={this.onMessageChange.bind(this)}  *//>
          </FormGroup>
          <SubmitButton type="submit" value="Send">أرسل</SubmitButton>
        </Form>
      
    </Wrapper>
  );
}
  
export default ContactForm;